import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import LanguageDropdown from "../TopbarDropdown/LanguageDropdown";
import CryptoJS from 'crypto-js';
import useDecryptedAuth from "../../../Utils/useDecryptedAuth";
import i18next from 'i18next';


const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const decryptedAuth = useDecryptedAuth();

  
  const [username, setusername] = useState({fullname: 'Admin', symbol: 'A'});
  React.useEffect(() => {
    let capitalizedUsername = decryptedAuth.username.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });

    let words = decryptedAuth.username.split(' ');
    let firstCharacters = words.map(word => word.charAt(0).toUpperCase());
    let combined = firstCharacters.join('');

    setusername({
      ...username,
      fullname: capitalizedUsername,
      symbol: combined,
    });
  }, []);


  return (
    <React.Fragment>
      {/* <LanguageDropdown /> */}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <a href=" https://tokenizedgreensupport2024.zendesk.com/hc/es" target="_blank" rel="noreferrer">
            <Button color="primary" className="btn btn-primary">
            <b>{i18next.t("Need Help")}</b>
            </Button>
          </a>
          <span className="name-logo">{username.symbol}</span>

          <span className="d-none d-xl-inline-block ms-2 me-1">{username.fullname}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item"><i className="bx bx-user font-size-16 align-middle me-1" /> {i18next.t("Profile_Menu Profile Settings")}</Link>
          <Link to="/payment-preference" className="dropdown-item"><i className="bx bx-money font-size-16 align-middle me-1" /> {i18next.t("Profile_Menu Payment Preference")}</Link>
          <Link to="/kyb" className="dropdown-item"><i className="bx bx-briefcase-alt font-size-16 align-middle me-1" /> {i18next.t("Profile_Menu KYB")}</Link>
          <Link to="/changepassword" className="dropdown-item"><i className="bx bx-lock font-size-16 align-middle me-1" />{i18next.t("Profile_Menu Change Password")}</Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item"><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /><span>{i18next.t("Profile_Menu Logout")}</span></Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
