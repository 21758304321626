import { useHistory } from "react-router-dom";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import useDecryptedAuth from "./useDecryptedAuth";


const useRequests = () => {
  const history = useHistory();
  const decryptedAuth = useDecryptedAuth();


  // const res = await getRequest('api-name');
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const getRequest = async (apiName) => {
    const headers = {
      'Content-Type': 'application/json',
      'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
      'accesstoken': 'Bearer ' + decryptedAuth.accesstoken,
      'lang': localStorage.getItem("I18N_LANGUAGE"),
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${apiName}`,
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        if(res.data) {
            const bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
            const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: originalText,
            };
        }
        else {
            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: [],
            };
        }
      } else if (res.code === 403) {
        history.push('/logout', { code: 401, status: 'error', message: res.message });
      } else {
        return {
            code: res.code,
            status: res.status,
            message: res.message,
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  // const jsonpar = JSON.stringify({
  //   old_password: values.old_password,
  //   new_password: values.new_password,
  //   new_confirm_password: values.new_confirm_password,
  // });
  // const res = await postRequest('api-name', jsonpar);
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const postRequest = async (apiName, jsonpar) => {
    const headers = {
      'Content-Type': 'application/json',
      'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
      'accesstoken': 'Bearer ' + decryptedAuth.accesstoken,
      'lang': localStorage.getItem("I18N_LANGUAGE"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${apiName}`,
        { data: CryptoJS.AES.encrypt(jsonpar, process.env.REACT_APP_USER_TOKEN_SECRET).toString() },
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        if(res.data) {
            const bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
            const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: originalText,
            };
        }
        else {
            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: [],
            };
        }
      } else if (res.code === 403) {
        history.push('/logout', { code: 401, status: 'error', message: res.message });
      } else {
        return {
            code: res.code,
            status: res.status,
            message: res.message,
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  // const formData = new FormData();
  // formData.append("id", atob(props.match.params.id));
  // formData.append("file", file);
  // formData.append("fileName", fileName);
  // const res = await postRequestByFormData('api-name', formData);
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const postRequestByFormData = async (apiName, formData) => {
    const headers = {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
        'Content-Length': '<calculated when request is sent>',
        'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
        'accesstoken': 'Bearer ' + decryptedAuth.accesstoken,
        'lang': localStorage.getItem("I18N_LANGUAGE"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${apiName}`,
        formData,
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        if(res.data) {
            const bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
            const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: originalText,
            };
        }
        else {
            return {
                code: res.code,
                status: res.status,
                message: res.message,
                data: [],
            };
        }
      } else if (res.code === 403) {
        history.push('/logout', { code: 401, status: 'error', message: res.message });
      } else {
        return {
            code: res.code,
            status: res.status,
            message: res.message,
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  // const res = await getRequestForBinance();
  // if (res && res.code === 200) {
  //   setData(res.data);
    
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'success',
  //     message: res.message,
  //   }));
  // }
  // else {
  //   setAlertData((AlertData) => ({
  //     ...AlertData,
  //     variant: 'error',
  //     message: res.message,
  //   }));
  // }
  // resetAlertData();
  // Above text is an example of below function how to call below function
  const getRequestForBinance = async () => {
    const headers = {
      'Content-Type': 'application/json'
    };

    try {
      const response = await axios.get(
        'https://api.binance.com/api/v3/ticker/price',
        { headers: headers }
      );

      const res = response.data;
      
      if (res) {
        return {
            code: 200,
            status: 'SUCCESS',
            message: 'SUCCESS',
            data: res,
        };
      } else {
        return {
            code: 401,
            status: 'ERROR',
            message: 'ERROR',
            data: [],
        };
      }
    } catch (error) {
      console.error("Error: ", error);
      return {
            code: 401,
            status: 'ERROR',
            message: error.message,
            data: [],
      };
    }
  };

  return { getRequest, postRequest, postRequestByFormData, getRequestForBinance };
};

export default useRequests;