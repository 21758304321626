export function checkImageValidation(file) {
    var imageExtension = ["png", "jpeg", "jpg"];
    var fileName = file.name;
    var fileExtension = fileName.split(".")[1];
    if (parseFloat(file.size) > 5242880) {
        return {
            status: false, 
            msg: "Maximum size allowed is 5MB"
        };
    }
    else if (!imageExtension.includes(fileExtension.toLowerCase())) {
        return {
            status: false, 
            msg: "Invalid Extension"
        };
    }
    else {
        return {
            status: true,
            msg: ""
        };
    }
}

export function checkFileValidation(file) {
    var filesExtension = ["png", "gif", "jpeg", "jpg", "svg", "xlsx", "xls", "csv", "pdf", "doc", "docx", "txt"];
    var fileName = file.name;
    var fileExtension = fileName.split(".")[1];
    if (parseFloat(file.size) > 5242880) {
        return {
            status: false, 
            msg: "Maximum size allowed is 5MB"
        };
    }
    else if (!filesExtension.includes(fileExtension.toLowerCase())) {
        return {
            status: false,
            msg: "Invalid Extension"
        };
    }
    else {
        return {
            status: true,
            msg: ""
        };
    }
}

export function checkCSVFileValidation(file) {
    var filesExtension = ["csv"];
    var fileName = file.name;
    var fileExtension = fileName.split(".")[1];
    if (parseFloat(file.size) > 5242880) {
        return {
            status: false, 
            msg: "Maximum size allowed is 5MB"
        };
    }
    else if (!filesExtension.includes(fileExtension.toLowerCase())) {
        return {
            status: false,
            msg: "Invalid Extension"
        };
    }
    else {
        return {
            status: true,
            msg: ""
        };
    }
}