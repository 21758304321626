import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const PropertyDetailsStatus = props => {
  //meta title
  document.title = i18next.t('Status');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const handleLoadGetTransactionInfo = async () => {
    const res = await getRequest('get-transaction-info');
    if (res && res.code === 200) {
      // let originalText = res.data;
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  React.useEffect(() => {
    handleLoadGetTransactionInfo();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="p-0 container-fluid">
        <Row className="g-0">
          <Col className="LeftImg">
            <div className="auth-full-page-content p-4 bg-white">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="/" className="auth-logo-light">
                      <div className="mb-1">
                        <span className=""> <img src={logo} alt="" height="60" /> </span>
                      </div>
                    </Link>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="main-kyc-title text-center">{i18next.t('Status')}</h5>
                    </div>
                    <div className="mt-4">
                      <div className="p-2">
                        {props.match.params.status === 'success'?
                        <h5 className="registrationDone text-center" dangerouslySetInnerHTML={{ __html: i18next.t('Property_Detail PG success message') }} />
                        :
                        <h5 className="registrationDone text-center text-danger" dangerouslySetInnerHTML={{ __html: i18next.t('Property_Detail PG failure message') }} />
                        }
                      </div>
                      <div className="mt-4 text-center">
                        <p className="footer-text-color"> {i18next.t('KYC_Verification PG Go back to')} &nbsp;<Link to="/portfolio" className="font-weight-medium text-underline">{i18next.t('My Portfolio')}</Link> </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="footer-text-color"> © {new Date().getFullYear()} Tokenizedgreen.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="rightImg">
            <div className="bgLogin"></div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PropertyDetailsStatus;
