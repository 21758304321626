import React from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Form, } from "reactstrap";

// Formik Validation
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Kyc = () => {
  //meta title
  document.title = i18next.t('KYC_Verification PG document title');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};

  const [ KYCStatus, setKYCStatus ] = React.useState('Pending');
  const [ KYCLink, setKYCLink ] = React.useState('');
  const handleLoadGetKYCInfo = async () => {
    const res = await getRequest('get-kyc-info');
    if (res && res.code === 200) {
      let originalText = res.data;
      
      setKYCStatus(originalText.user_info.kyc_status);
      
      if(originalText.user_info.kyc_status === 'Pending') {
        setKYCLink(originalText.kyc_link);
      }
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  React.useEffect(() => {
    handleLoadGetKYCInfo();
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem KYC_Verification')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">

                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc" className="active"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">

                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="crypto-buy-sell-nav">
                          {KYCStatus === 'Approved' ?
                            <p className="alert alert-success successfullyKYC" dangerouslySetInnerHTML={{ __html: i18next.t('KYC_Verification PG Approved P1') }} />
                          : KYCStatus === 'Cancelled' ?
                            <p className="alert alert-success successfullyKYC" dangerouslySetInnerHTML={{ __html: i18next.t('KYC_Verification PG Cancelled P1') }} />
                          : KYCStatus === 'Rejected' ?
                            <p className="alert alert-success successfullyKYC" dangerouslySetInnerHTML={{ __html: i18next.t('KYC_Verification PG Rejected P1') }} />
                          :
                            <p className="alert successfullyKYC"><span className="highlight-span">{KYCLink !== ''? 
                            <>
                              <div className="crypto-buy-sell-nav highlightText">
                                <p className="card-title-desc mb-1 kyc-title mb-3"><b>{i18next.t('KYC PG document P1')}</b></p>
                                <p className="card-title-desc mb-3 kyc-text mb-3">{i18next.t('KYC PG document P2')}</p>
                                <a href={KYCLink} target="_blank" rel="noreferrer">{i18next.t('Start KYC with Ondato')}</a>
                                <p className="card-title-desc mb-3 kyc-title mb-3 mt-4"><b>{i18next.t('Note')}:</b></p>
                                <p className="card-title-desc mb-3 kyc-text mb-3" dangerouslySetInnerHTML={{ __html: i18next.t('KYC PG document P7') }}></p>
                              </div>
                            </>
                            : i18next.t('Processing...') }</span> </p>
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Kyc);
