import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, Label, FormFeedback, Modal } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import ImageGallery from 'react-image-gallery';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import { checkFileValidation } from "../../Utils/Common";
import useRequests from '../../Utils/useRequests';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";
import i18next from 'i18next';
import volgreen from "../../assets/images/Picture1.png";
import Jose1 from "../../assets/images/Picture2.png";
import Alexandre from "../../assets/images/Picture3.png";
import Josep from "../../assets/images/Picture4.png";
import Ramon from "../../assets/images/Picture7.png";
import Jose2 from "../../assets/images/Picture6.png";

const PropertyDetails = props => {
  //meta title
  document.title = i18next.t('Property_Detail PG document title');
  const { getRequest, postRequest, getRequestForBinance, postRequestByFormData } = useRequests();
  const decryptedAuth = useDecryptedAuth();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async () => {
    
    if(decryptedAuth.kyc_status !== 'Approved') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: i18next.t('For this feature, KYC has been required.'),
      }));
    }
    else {
      let EuroInvestment = 0;
      if (validation1.values.number_of_tokens !== '' || parseFloat(validation1.values.number_of_tokens) > 0) {
        EuroInvestment = parseFloat(validation1.values.number_of_tokens) * PropertyInfo.token_price;
      }

      if(validation1.values.is_used_euro_wallet) {
        let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance);
        if (TransactionData.length > 0) {
          let filteredData = TransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        if (PropertyCryptoTransactionData.length > 0) {
          let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
          let TotalPendingAmount = 0;
          filteredData.forEach(element => {
            TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
          });
          EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
        }
        
        if(EuroInvestment > EuroAvailableBalance) {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: EuroInvestment-EuroAvailableBalance,
          });
        }
        else {
          validation2.setValues({
            ...validation2.values,
            transfer_euro: 0,
          });
        }
      }
      else {
        validation2.setValues({
          ...validation2.values,
          transfer_euro: EuroInvestment,
        });
      }
      if(validation1.values.number_of_tokens !== '' && parseFloat(validation1.values.number_of_tokens) > 0){
        setStandardModal1(true);
      }
      
    }
  }

  const handleClickCheckbox = (is_used_euro_wallet, number_of_tokens) => {
    let isChecked = validation1.values.is_used_euro_wallet;
    if(is_used_euro_wallet) {
      isChecked = !validation1.values.is_used_euro_wallet;
      validation1.setValues({ ...validation1.values, is_used_euro_wallet: isChecked });
    }

    let submit3 = document.getElementById('submit3');
    if(validation1.values.currency === 'MATIC-Wallet' || validation1.values.currency === 'USDT-Wallet') {
      submit3 = document.getElementById('submit1');
    }
    if (isChecked && validation1.values.currency.split('-')[0] === 'Euro' && number_of_tokens !== '' && parseFloat(number_of_tokens) > 0) {
      let EuroInvestment = parseFloat(number_of_tokens) * PropertyInfo.token_price;

      let EuroAvailableBalance = parseFloat(CryptoData.euro_available_balance);
      if (TransactionData.length > 0) {
        let filteredData = TransactionData.filter(item => item.status === 'Pending');
        let TotalPendingAmount = 0;
        filteredData.forEach(element => {
          TotalPendingAmount = TotalPendingAmount + parseFloat(element.total_amount);
        });
        EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
      }
      if (PropertyCryptoTransactionData.length > 0) {
        let filteredData = PropertyCryptoTransactionData.filter(item => item.status === 'Pending');
        let TotalPendingAmount = 0;
        filteredData.forEach(element => {
          TotalPendingAmount = TotalPendingAmount + parseFloat(element.used_euro_wallet_amount);
        });
        EuroAvailableBalance = EuroAvailableBalance - TotalPendingAmount;
      }
      
      if(EuroInvestment <= EuroAvailableBalance) {
        submit3.textContent = i18next.t('Submit');
        submit3.classList.add('submit');
      }
      else {
        submit3.textContent = i18next.t('Invest');
        submit3.classList.remove('submit');
      }
    } else {
      submit3.textContent = i18next.t('Invest');
      submit3.classList.remove('submit');
    }
  };

  const [YourInvestment, setYourInvestment] = React.useState(0);
  const [ShareOfTotalEquity, setShareOfTotalEquity] = React.useState(0);
  const handleChangeNumberOfTokens = async (tempNumberOfTokens, tempCurrency) => {
    setYourInvestment(0);
    setShareOfTotalEquity(0);
    if (parseFloat(tempNumberOfTokens) > 0 && tempCurrency !== '') {
      let EuroInvestment = tempNumberOfTokens * PropertyInfo.token_price;
      let TempYourInvestment = EuroInvestment;

      if (tempCurrency === 'BTC' || tempCurrency === 'ETH' || tempCurrency === 'MATIC') {
        let RateData = BinanceRatesData.find(item => item.symbol === tempCurrency + 'EUR');
        TempYourInvestment = EuroInvestment / parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }
      else if (tempCurrency === 'USDT' || tempCurrency === 'USDC') {
        let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + tempCurrency);
        TempYourInvestment = EuroInvestment * parseFloat(RateData.price);
        TempYourInvestment = TempYourInvestment.toFixed(8);
      }

      setYourInvestment(TempYourInvestment);
      setShareOfTotalEquity(((tempNumberOfTokens / PropertyInfo.total_token) * 100).toFixed(2));
    }

    if(validation1.values.currency === 'Euro' && validation1.values.is_used_euro_wallet) {
      handleClickCheckbox(false, tempNumberOfTokens);
    }
  }

  const [WrongModalOpen, setWrongModalOpen] = React.useState(false);
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      currency: 'BTC',
      number_of_tokens: '',
      is_used_euro_wallet: false,
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(i18next.t('Property_Detail PG Please select currency')),
      number_of_tokens: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Number of Tokens is required')),
    }),
    onSubmit: async (values) => {
      let openModal = false;
      if(values.currency === 'Euro') {
        openModal = true;
      }
      if(openModal) {
        let submit3 = document.getElementById('submit3');
        if (submit3.classList.contains('submit')) {
          // Open the "wrong modal"
          setWrongModalOpen(true);
        } else {
          togStandardModal1();
        }
      }
      else {
        if(decryptedAuth.kyc_status !== 'Approved') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('For this feature, KYC has been required.'),
          }));
        }
        else {
          try {
            let EuroInvestment = parseFloat(YourInvestment);
            let EuroInvestmentBalance = parseFloat(CryptoData.euro_available_balance);
            if (values.currency === 'BTC' || values.currency === 'ETH' || values.currency === 'MATIC') {
              let RateData = BinanceRatesData.find(item => item.symbol === values.currency + 'EUR');
              EuroInvestment = Math.round(EuroInvestment * parseFloat(RateData.price));
            }
            else if (values.currency === 'USDT' || values.currency === 'USDC') {
              let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + values.currency);
              EuroInvestment = Math.round(EuroInvestment / parseFloat(RateData.price));
            }
            else if (values.currency === 'MATIC-Wallet') {
              let values_currency = 'MATIC';
              let RateData = BinanceRatesData.find(item => item.symbol === values_currency + 'EUR');
              EuroInvestment = Math.round(EuroInvestment * parseFloat(RateData.price));

              EuroInvestmentBalance = Math.round(CryptoData.matic_available_balance * parseFloat(RateData.price));
              if(values.is_used_euro_wallet) {
                EuroInvestmentBalance += parseFloat(CryptoData.euro_available_balance);
              }
            }
            else if (values.currency === 'USDT-Wallet') {
              let values_currency = 'USDT';
              let RateData = BinanceRatesData.find(item => item.symbol === 'EUR' + values_currency);
              EuroInvestment = Math.round(EuroInvestment / parseFloat(RateData.price));

              EuroInvestmentBalance = Math.round(CryptoData.usdt_available_balance / parseFloat(RateData.price));
              if(values.is_used_euro_wallet) {
                EuroInvestmentBalance += parseFloat(CryptoData.euro_available_balance);
              }
            }

            if (parseFloat(EuroInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
              setAlertData((AlertData) => ({
                ...AlertData,
                variant: 'error',
                message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
              }));
            }
            else if ((values.currency === 'MATIC-Wallet' || values.currency === 'USDT-Wallet') && parseFloat(EuroInvestment) > parseFloat(EuroInvestmentBalance)) {
              setAlertData((AlertData) => ({
                ...AlertData,
                variant: 'error',
                message: i18next.t('Property_Detail PG Insufficient balance'),
              }));
            }
            else if (values.currency === 'USDT-Wallet' && parseFloat(CryptoData.matic_available_balance) < 0.1) {
              setAlertData((AlertData) => ({
                ...AlertData,
                variant: 'error',
                message: i18next.t('Property_Detail PG if you want to invest using USDT wallet, you must have at least 1 MATIC in your MATIC wallet'),
              }));
            }
            else {
              let submit1 = document.getElementById('submit1');
              submit1.disabled = true;
              submit1.textContent = i18next.t('Processing...');

              let RequestURL  = 'property-invest';
              let jsonpar = JSON.stringify({
                p_id: 1,
                currency: values.currency,
                number_of_tokens: values.number_of_tokens,
                is_used_euro_wallet: values.is_used_euro_wallet,
              });
              if(values.currency === 'MATIC-Wallet' || values.currency === 'USDT-Wallet') {
                RequestURL  = 'property-invest-by-wallet';
                jsonpar = JSON.stringify({
                  p_id: 1,
                  currency: values.currency.split('-')[0],
                  number_of_tokens: values.number_of_tokens,
                  is_used_euro_wallet: values.is_used_euro_wallet,
                });
              }
              const res = await postRequest(RequestURL, jsonpar);
              if (res && res.code === 200) {
                if (res.data.length > 0 && res.data !== '') {
                  window.open(res.data, '_blank');
                }
                else {
                  validation1.setValues({
                    ...validation1.values,
                    currency: 'BTC',
                    number_of_tokens: '',
                    is_used_euro_wallet: false,
                  });
                  validation2.setValues({
                    ...validation2.values,
                    transfer_euro: 0,
                    transaction_information: '',
                    conditions: false,
                  });
                  setFile(null);
                  setFileName('');

                  setYourInvestment(0);
                  setShareOfTotalEquity(0);
                  handleLoadGetBinanceRates();
                  handleLoadGetCryptoData();
                  handleLoadGetTransactionData();
                  handleLoadGetPropertyCryptoTransactionData();
                  setTimeout(() => {
                    handleClickCryptoBalanceRefresh();
                  }, 7000);

                  setAlertData((AlertData) => ({
                    ...AlertData,
                    variant: 'success',
                    message: res.message,
                  }));
                }
              }
              else {
                setAlertData((AlertData) => ({
                  ...AlertData,
                  variant: 'error',
                  message: res.message,
                }));
              }

              submit1.disabled = false;
              submit1.textContent = i18next.t('Invest');
            }
          } catch (error) {
            console.error("Error : ", error);
          }
        }
        resetAlertData();
      }
    }
  });

  const [File, setFile] = useState();
  const [FileName, setFileName] = useState('');
  const saveFile = (e) => {
    if((e.target.files).length > 0) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      var validationRes = checkFileValidation(e.target.files[0]);
      if (!validationRes.status) {
        setFile();
        setFileName('');

        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'error',
          message: validationRes.msg,
        }));
        resetAlertData();

        return false;
      }
    }
  };

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      transfer_euro: 0,
      transaction_information: '',
      conditions: false,
    },
    validationSchema: Yup.object({
      transaction_information: Yup.string()
        .matches(
          /^[#0-9a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Property_Detail PG Transaction Information is required')),
        conditions: Yup.boolean()
      .oneOf([true], i18next.t('You must accept the conditions of the whitepaper and the personal loan agreement to continue!'))
    }),
    onSubmit: async (values) => {
      try {
        if (validation1.values.number_of_tokens === '' || validation1.values.number_of_tokens === 0) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Number of Tokens is required'),
          }));
        }
        else if (parseFloat(YourInvestment) < parseFloat(PropertyInfo.minimum_investment_allocation)) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Minimum investment Allocation is required'),
          }));
        }
        else if (FileName === '') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('Property_Detail PG Please select a transaction proof!'),
          }));
        }
        else {
          let submit1 = document.getElementById('submit2');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const formData = new FormData();
          formData.append("p_id", 1);
          formData.append("currency", validation1.values.currency.split('-')[0]);
          formData.append("number_of_tokens", validation1.values.number_of_tokens);
          formData.append("is_used_euro_wallet", validation1.values.is_used_euro_wallet);
          formData.append("transfer_euro", values.transfer_euro);
          formData.append("transaction_information", values.transaction_information);
          formData.append("file", File);
          formData.append("fileName", FileName);
          const res = await postRequestByFormData('property-invest-by-bank', formData);
          if (res && res.code === 200) {
            validation1.setValues({
              ...validation1.values,
              currency: 'Euro',
              number_of_tokens: '',
              is_used_euro_wallet: false,
            });
            validation2.setValues({
              ...validation2.values,
              transfer_euro: 0,
              transaction_information: '',
              conditions: false,
            });
            setFile(null);
            setFileName('');

            setYourInvestment(0);
            setShareOfTotalEquity(0);
            handleLoadGetBinanceRates();
            handleLoadGetCryptoData();
            handleLoadGetTransactionData();
            handleLoadGetPropertyCryptoTransactionData();
            setTimeout(() => {
              handleClickCryptoBalanceRefresh();
            }, 7000);

            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Submit');

          setStandardModal1(false);
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const handleSubmit4 = async () => {
    let submit1 = document.getElementById('submit4');
    submit1.disabled = true;
    submit1.textContent = i18next.t('Processing...');

    const formData = new FormData();
    formData.append("p_id", 1);
    formData.append("currency", validation1.values.currency.split('-')[0]);
    formData.append("number_of_tokens", validation1.values.number_of_tokens);
    formData.append("is_used_euro_wallet", validation1.values.is_used_euro_wallet);
    formData.append("transfer_euro", 0);
    formData.append("transaction_information", 'Used Euro Wallet Balance');
    const res = await postRequestByFormData('property-invest-by-bank', formData);
    if (res && res.code === 200) {
      validation1.setValues({
        ...validation1.values,
        currency: 'Euro',
        number_of_tokens: '',
        is_used_euro_wallet: false,
      });
      validation2.setValues({
        ...validation2.values,
        transfer_euro: 0,
        transaction_information: '',
        conditions: false,
      });
      setFile(null);
      setFileName('');

      setYourInvestment(0);
      setShareOfTotalEquity(0);
      handleLoadGetBinanceRates();
      handleLoadGetCryptoData();
      handleLoadGetTransactionData();
      handleLoadGetPropertyCryptoTransactionData();
      setTimeout(() => {
        handleClickCryptoBalanceRefresh();
      }, 7000);

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();

    submit1.disabled = false;
    submit1.textContent = i18next.t('Invest');

    setWrongModalOpen(false);
  }

  const [BinanceRatesData, setBinanceRatesData] = React.useState([]);
  const handleLoadGetBinanceRates = async () => {
    const res = await getRequestForBinance();
    if (res && res.code === 200) {
      setBinanceRatesData(res.data);
    }
  }

  const [PropertyInfo, setPropertyInfo] = React.useState([]);
  const [PropertyImages, setPropertyImages] = React.useState([]);
  const [impactDoc, setImpactDoc] = React.useState('');

  const handleLoadGetPropertyInfo = async () => {
    const jsonpar = JSON.stringify({
        p_id: 1
    });
    const res = await postRequest('get-property-info', jsonpar);
    if (res && res.code === 200) {
        setPropertyInfo(res.data.property);

        setImpactDoc(process.env.REACT_APP_API_URL+res.data.docUrl+res.data.property.impact_document);

        const filePath = res.data.url;
        const images = res.data.images;

        let tempArr = [];
        images.forEach(element => {
            const temp = {
                original: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
                thumbnail: `${process.env.REACT_APP_API_URL}${filePath}${element.image}`,
            };
            tempArr.push(temp);
        });
        setPropertyImages(tempArr);
    } else {
        setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
        }));
    }
    resetAlertData();
};
  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async () => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const handleClickCryptoBalanceRefresh = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      setCryptoData(res.data);
    }
  }

  const [TransactionData, setTransactionData] = React.useState([]);
  const handleLoadGetTransactionData = async () => {
    const jsonpar = JSON.stringify({
      currency_type: 'Euro',
    });
    const res = await postRequest('get-withdrawal-transaction-data', jsonpar);
    if (res && res.code === 200) {
      setTransactionData(res.data);
    }
  }
  const [PropertyCryptoTransactionData, setPropertyCryptoTransactionData] = React.useState([]);
  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      setPropertyCryptoTransactionData(res.data.transaction);
    }
  }
  const [SiteSettings, setSiteSettings] = React.useState([]);
  const handleLoadGetSiteSettings = async () => {
    const res = await getRequest('get-site-settings');
    if (res && res.code === 200) {
      setSiteSettings(res.data);
    }
  }

  const [PropertiesTeamTBody, setPropertiesTeamTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.team.map((element, index) => (
      <Col className="col-lg-2 col-md-4 col-sm-6 col-12" key={index}>   
        <div className="card">
          <div className="card-body">
            <div className="text-box">
              <img src={`${process.env.REACT_APP_API_URL}${tempData.url}${element.profile_pic}`} alt="" className="team-img" />
              <div className="team-info">
                <p className="team-name">{localStorage.getItem("I18N_LANGUAGE")=="eng"?element.name: element.name_sp}</p>
                <p className="team-position">{localStorage.getItem("I18N_LANGUAGE")=="eng"?element.designation: element.designation_sp}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
     
    ));

    setPropertiesTeamTBody(tempTBody);
  }

  const handleLoadGetPropertyTeam = async () => {
    const jsonpar = JSON.stringify({
      p_id: 1
    });
    const res = await postRequest('get-property-team', jsonpar);
    if (res && res.code === 200) {
      let originalText = res.data;
      handleT1Body(originalText);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="5" className="text-center">No records found</td>
                      </tr>);

      setPropertiesTeamTBody(tempTBody);

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [PropertiesDocumentsTBody, setPropertiesDocumentsTBody] = React.useState('');
  const handleT2Body = async (tempData, lang) => {
    let tempTBody = tempData.documents.map((element, index) => (
      <div className="fileBox mt-2" key={index}>
          <i className="bx bx-file"></i>
          <a href={process.env.REACT_APP_API_URL+tempData.url+element.document} target="_blank" rel="noopener noreferrer">
              <span>{localStorage.getItem("I18N_LANGUAGE")=="eng"?element.document_name: element.document_name_sp}</span>
          </a>
      </div>
    ));

    setPropertiesDocumentsTBody(tempTBody);
  }

  const handleLoadGetPropertyDocuments = async (lang) => {
    const jsonpar = JSON.stringify({
      p_id: 1,
    });
    const res = await postRequest('get-property-documents', jsonpar);

    if (res && res.code === 200) {
      let originalText = res.data;
      handleT2Body(originalText);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="5" className="text-center">No records found</td>
                      </tr>);

      setPropertiesDocumentsTBody(tempTBody);

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  React.useEffect(() => {
    handleLoadGetBinanceRates();
    handleLoadGetPropertyInfo();
    handleLoadGetCryptoData();
    handleLoadGetTransactionData();
    handleLoadGetPropertyCryptoTransactionData();
    handleLoadGetSiteSettings();
    handleLoadGetPropertyTeam();
    handleLoadGetPropertyDocuments();

    validation1.setValues({ ...validation1.values, currency: 'Euro' });
    handleChangeNumberOfTokens('', 'Euro');
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid className="PropertyDetails">
          <Row className="mt-2">
            <Col className="col-md-12">
              <div className="fullWidthLink">
                <Link className="back-link" to="/dashboard"><i className="bx bx-left-arrow-alt"></i> {i18next.t('Back')}</Link>
              </div>
              <h5 className="mb-3 mainTitle"> {localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_name:PropertyInfo.translated_property_name}</h5>
              <div className="propert-short-des" dangerouslySetInnerHTML={{ __html: localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_sub_title:PropertyInfo.translated_property_sub_title }} />
              <p className="location"><i className="bx bx-map-pin"></i> <span>{localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_address:PropertyInfo.translated_property_address}</span></p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-12">
              <div className="leftBar">
                <Card className="mb-0">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <Row>
                        <div className="wrapper">
                          <ImageGallery thumbnailPosition="left" items={PropertyImages} />
                        </div>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="RightBar">
                <Card className="light-green zoom-in-out-box">
                  <CardBody>
                    <div className="product-detai-imgs">
                      <div className="clearfix"></div>
                      <p className="increments-text">{i18next.t('Property_Detail PG Type a number of tokens you want')}</p>
                      <div className="clearfix"></div>
                      <div className="AvailableBox fullWidth">
                        <div className="mb-3 right-selectbox">
                          <div className="leftBox Cstm-fullWidth">
                            <select className="form-select" name="currency" value={validation1.values.currency}
                              onChange={(e) => {
                                validation1.setValues({ ...validation1.values, currency: e.target.value });
                                handleChangeNumberOfTokens(validation1.values.number_of_tokens, e.target.value.split('-')[0]);
                              }}
                            >
                              <option value="">{i18next.t('Select currency')}</option>
                              {/* <option value="BTC">BTC</option>
                              <option value="ETH">ETH</option>
                              <option value="MATIC">MATIC</option>
                              <option value="USDT">USDT-ERC20</option>
                              <option value="USDC">USDC-ERC20</option> */}
                              <option value="Euro">{i18next.t('Euro Bank Transfer')}</option>
                              <option value="MATIC-Wallet">MATIC Wallet</option>
                              <option value="USDT-Wallet">USDT Wallet</option>
                            </select>
                            {validation1.touched.currency && validation1.errors.currency ? (
                              <FormFeedback type="invalid">{validation1.errors.currency}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="rightBox Cstm-fullWidth">
                            <Input
                              name="number_of_tokens"
                              type="text"
                              placeholder={i18next.t('Enter number of tokens')}
                              onChange={(e) => {
                                validation1.handleChange(e);
                                handleChangeNumberOfTokens(e.target.value, validation1.values.currency.split('-')[0]);
                              }}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.number_of_tokens || ""}
                              invalid={
                                validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? true : false
                              }
                            />
                            {validation1.touched.number_of_tokens && validation1.errors.number_of_tokens ? (
                              <FormFeedback type="invalid">{validation1.errors.number_of_tokens}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        {/* {validation1.values.currency === 'Euro'? */}
                        <div className="form-check mb-3">
                          <input type="checkbox" className="form-check-input" id="is_used_euro_wallet" name="is_used_euro_wallet" checked={validation1.values.is_used_euro_wallet || false} onClick={(e) => handleClickCheckbox(true, validation1.values.number_of_tokens)}/>
                          <label className="form-check-label" htmlFor="is_used_euro_wallet"> Euro {i18next.t('Wallet Balance')}: <b>€{CryptoData.euro_available_balance}</b> </label>
                        </div>
                        {/* : ''} */}
                        {validation1.values.currency === 'MATIC-Wallet'?
                        <div className="form-check mb-3">
                          <label className="form-check-label"> MATIC {i18next.t('Wallet Balance')}: <b>{CryptoData.matic_available_balance}</b> </label>
                        </div>
                        : validation1.values.currency === 'USDT-Wallet'?
                        <div className="form-check mb-3">
                          <label className="form-check-label"> USDT {i18next.t('Wallet Balance')}: <b>{CryptoData.usdt_available_balance}</b> </label>
                        </div>
                        : ''}
                      </div>
                      {validation1.values.currency !== 'Euro'?
                        <Button color="primary" type="button" className="ExpressInterest"
                          id="submit1"
                          onClick={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit();
                            return false;
                          }}>{i18next.t('Invest')}</Button>
                      :
                        <Button color="primary" type="button" className="ExpressInterest" 
                          id="submit3"
                          onClick={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit();
                            return false;
                          }}>{i18next.t('Invest')}</Button>
                      }
                      <div className="total-investment">
                        {validation1.values.currency !== '' ?
                          <>
                            <p className="total-purchase-text">{i18next.t('Your investment')}</p>
                            <p className="total-purchase">{YourInvestment} {validation1.values.currency.split('-')[0]}</p>
                          </>
                          : ''}
                      </div>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Minimum investment Allocation')} : <b>{PropertyInfo.minimum_investment_allocation} EUR</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Available number of Tokens')} : <b>{PropertyInfo.total_token}</b></p>
                      <p className="ShareAllocation">{i18next.t('Property_Detail PG Share of total equity')} : <b>{ShareOfTotalEquity}%</b></p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container >

        <div className="clearfix"></div>

        <Container fluid>
          <div className="contentt fullWidth">
            <Card>
              <CardBody><div className="propert-short-des" dangerouslySetInnerHTML={{ __html: localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_description:PropertyInfo.translated_property_description }} /></CardBody>
            </Card>
            <Card>
              <CardBody><div className="propert-short-des" dangerouslySetInnerHTML={{ __html: localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_impact:PropertyInfo.translated_property_impact }} />
              <div className="fileBox mt-2">
                  <i className="bx bx-file"></i>
                  <a href={impactDoc} target="_blank" rel="noopener noreferrer">
                      <span>{localStorage.getItem("I18N_LANGUAGE")=="eng"?PropertyInfo.impact_document_name: PropertyInfo.impact_document_name_sp}</span>
                  </a>
              </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody><div className="propert-short-des" dangerouslySetInnerHTML={{ __html: localStorage.getItem("I18N_LANGUAGE")=="eng" ? PropertyInfo.property_developer:PropertyInfo.translated_property_developer }} /></CardBody>
            </Card>

            <Card>
              <CardBody>
                <h5 className="property-inner-title">{i18next.t('Team')} :</h5>
                <div className="row">   
                  {PropertiesTeamTBody}

                  <Col className="col-lg-2 col-md-4 col-sm-6 col-12">   
                    <div className="">
                      <div className="">
                        <div className="common-links">
                          <a  target="_blank" rel="noopener noreferrer">
                            <i className="bx bx-globe"></i><span>{PropertyInfo.property_website}</span>
                          </a>
                          <a  target="_blank" rel="noopener noreferrer">
                            <i className="bx bxl-instagram"></i><span>{PropertyInfo.property_instagram}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="mt-3">
                  <h5 className="property-inner-title mb-3">{i18next.t('Location')} :</h5>
                </div>
                <iframe
                  title="Google Map"
                  className="googleMap"
                  loading="lazy"
                  allowFullScreen
                  src={`https://www.google.com/maps?q=${PropertyInfo.latitude},${PropertyInfo.longitude}&z=14&output=embed`}
                  style={{ border: 0, width: '100%', height: '400px' }}
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </CardBody>
            </Card>

            <Card>
            <CardBody>
                <h5 className="property-inner-title mb-4">{i18next.t('Documents')} :</h5>
                <div className="row mb-4">
                    <Col className="col-12 mb-3">
                      {PropertiesDocumentsTBody}
                    </Col>
                </div>
            </CardBody>
            </Card>

          </div>
        </Container>


        <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0">{i18next.t('Property_Detail PG Upload Transaction Information')}</h5>
            <button type="button" onClick={(e) => setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body row">
            <div className="col-lg-6">
              <div className="bgBankInfo">
                <p className="ShareAllocation ShareAllocation-details mb-3">{i18next.t('Property_Detail PG Please transfer')} <b>{validation2.values.transfer_euro} EUR</b> {i18next.t('Property_Detail PG to below account')}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Beneficiary Name')} :</b> {SiteSettings.beneficiary_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Bank Name')} :</b> {SiteSettings.bank_name}</p>
                <p className="accountInfoDetails"><b>{i18next.t('IBAN')} :</b> {SiteSettings.iban_no}</p>
                {/* <p className="accountInfoDetails"><b>{i18next.t('Routing Number')} :</b> {SiteSettings.routing_number}</p> */}
                <p className="accountInfoDetails"><b>{i18next.t('SWIFT Code')} :</b> {SiteSettings.swift_code}</p>
                <p className="accountInfoDetails"><b>{i18next.t('Send us proof of payment with concept')} :</b> {i18next.t('Valgreen Project')} - {validation1.values.number_of_tokens} Tokens </p>

                <div className="uploaddocument">
                  <CopyToClipboard text={`${i18next.t('Beneficiary Name')} : ${SiteSettings.beneficiary_name}
                    ${i18next.t('Bank Name')} : ${SiteSettings.bank_name}
                    ${i18next.t('IBAN')} : ${SiteSettings.iban_no}
                    ${i18next.t('SWIFT Code')} : ${SiteSettings.swift_code}`}>
                    <Button color="primary" className="ExpressInterest btn btn-light">{i18next.t('Property_Detail PG Copy Bank Details')}</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bgBankInfo bg-white">
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Transaction Information')}</Label>
                  <Input type="text"
                    name="transaction_information"
                    placeholder={i18next.t('Property_Detail PG Please Enter Transaction Information')}
                    onChange={validation2.handleChange}
                    onBlur={validation2.handleBlur}
                    value={validation2.values.transaction_information || ""}
                  />
                  {validation2.touched.transaction_information && validation2.errors.transaction_information ? (
                    <FormFeedback type="invalid">{validation2.errors.transaction_information}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">{i18next.t('Property_Detail PG Upload Transaction Proof')}</Label>
                  <Input type="file" onChange={saveFile}/>
                </div>

                <div className="form-check mb-3 upload-documents-chk">
                  <input type="checkbox" className="form-check-input" id="customControlInline" name="conditions" checked={validation2.values.conditions || false} onClick={(e) => validation2.setValues({ ...validation2.values, conditions: !validation2.values.conditions })} />
                  <label className="form-check-label" htmlFor="customControlInline">  {i18next.t('Whitepaper and loan aggrement condition')} </label>
                  {validation2.touched.conditions && validation2.errors.conditions ? (
                    <FormFeedback type="invalid">{validation2.errors.conditions}</FormFeedback>
                  ) : null}
                </div>

                <Button color="primary" className="ExpressInterest mt-0"
                  id="submit2"
                  onClick={(e) => {
                    e.preventDefault();
                    validation2.handleSubmit();
                    return false;
                  }}
                >{i18next.t('Submit')}</Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={WrongModalOpen} toggle={() => setWrongModalOpen(false)} centered>
          <div className="modal-header">
            <button type="button" onClick={() => setWrongModalOpen(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <i className="fas fa-exclamation-triangle fa-3x text-warning"></i>
            <p className="mt-3">{i18next.t('Are you sure you want to submit?')}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setWrongModalOpen(false)}>
              {i18next.t('Close')}
            </button>
            <button type="button" className="btn btn-primary" 
              id="submit4"
              onClick={(e) => {
                handleSubmit4(e);
              }}>
              {i18next.t('Submit')}
            </button>
          </div>
        </Modal>
      </div >
    </React.Fragment >
  )
}

PropertyDetails.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default PropertyDetails
