import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import i18next from 'i18next';


const useDecryptedAuth = () => {
    const history = useHistory();
    
    try {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem("authUser"), process.env.REACT_APP_USER_TOKEN_SECRET);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error('Error decrypting authUser : ', error.message);
        history.push('/logout', { code: 401, status: 'error', message: i18next.t('Login PG User authentication changed') });
        return null;
    }
};


export default useDecryptedAuth;