import React from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, Button, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useDispatch } from "react-redux";
import { withRouter, Link, useLocation, useHistory } from "react-router-dom";

// actions
import { loginUser } from "../../store/actions";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";

import Snackbars from "../../Utils/Snackbars";
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken';
import i18next from 'i18next';


const Login = props => {
  //meta title
  document.title = i18next.t('Login PG document title');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { getRequestWOAccessToken, postRequestWOAccessToken } = useRequestsWOAccessToken();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18next.t('Please enter a valid email!'))
        .max(255)
        .required(i18next.t('Login PG Email is required')),
      password: Yup.string().required(i18next.t('Login PG Password is required')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          email: values.email,
          password: values.password,
        });
        const res = await postRequestWOAccessToken('login', jsonpar);
        if (res && res.code === 200) {
          let originalText = res.data;

          if (originalText.is_email_verified === 0 || originalText.is_phone_verified === 0) {
            history.push('/email-mobile-verification', { id: originalText.u_id, email: originalText.email, country_code: originalText.country_code, phone: originalText.phone, code: 401, status: 'error', message: res.message });
          }
          else if (originalText.is_auth_enabled === 1) {
            history.push('/google-auth', { id: originalText.u_id, email: originalText.email, password: values.password, code: 200, status: 'success', message: res.message });
          }
          else {
            i18next.changeLanguage(originalText.preferred_language);
            localStorage.setItem("I18N_LANGUAGE", originalText.preferred_language);

            values.username = originalText.first_name + ' ' + originalText.last_name;
            values.kyc_status = originalText.kyc_status;
            values.accesstoken = originalText.accesstoken;
            values.refreshtoken = originalText.refreshtoken;

            dispatch(loginUser(values, props.history, { code: 200, status: 'success', message: i18next.t('Login PG Login successful. Welcome back') }));
          }
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Log In');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });
  const [PreferredLanguage, setPreferredLanguage] = React.useState('eng');
  const onChangePreferredLanguage = (e) => {
    i18next.changeLanguage(e.target.value);
    setPreferredLanguage(e.target.value);
    localStorage.setItem("I18N_LANGUAGE", e.target.value);
  };
  React.useEffect(() => {
    const language = localStorage.getItem("I18N_LANGUAGE");
    if (!language)
      setPreferredLanguage("sp");
    else
      setPreferredLanguage(language);

    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
    }
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="p-0 container-fluid">
        <select className="form-select top-right-lang" name="preferred_language" value={PreferredLanguage} onChange={(e) => onChangePreferredLanguage(e)}>
          <option value={'eng'}>English</option>
          <option value={'sp'}>Spanish</option>
        </select>
        <Row className="g-0">
          <Col className="LeftImg">
            <div className="auth-full-page-content p-4 bg-white">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="/" className="auth-logo-light">
                      <div className="mb-1">
                        <span className=""> <img src={logo} alt="" height="60" /> </span>
                      </div>
                    </Link>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-black">{i18next.t('Login PG Welcome Back')} !</h5>
                      <p className="text-black">{i18next.t('Login PG Sign in to continue to')} Tokenizedgreen</p>
                    </div>
                    <div className="mt-4">
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Login PG Email')} :</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder={i18next.t('Login PG Enter email')}
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.email || ""}
                            invalid={
                              validation1.touched.email && validation1.errors.email ? true : false
                            }
                          />
                          {validation1.touched.email && validation1.errors.email ? (
                            <FormFeedback type="invalid">{validation1.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">{i18next.t('Login PG Password')} :</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder={i18next.t('Login PG Enter password')}
                            onChange={validation1.handleChange}
                            onBlur={validation1.handleBlur}
                            value={validation1.values.password || ""}
                            invalid={
                              validation1.touched.password && validation1.errors.password ? true : false
                            }
                          />
                          {validation1.touched.password && validation1.errors.password ? (
                            <FormFeedback type="invalid">{validation1.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="customControlInline" />
                          <label className="form-check-label" htmlFor="customControlInline" > {i18next.t('Login PG Remember me')} </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <Button
                            id="submit1"
                            onClick={(e) => {
                              e.preventDefault();
                              validation1.handleSubmit();
                              return false;
                            }}
                            data-toggle="modal"
                            data-target="#myModal" className="btn btn-primary btn-block"> {i18next.t('Log In')} </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted"> <i className="mdi mdi-lock me-1" /> {i18next.t('Login PG Forgot your password?')} </Link>
                        </div>
                      </Form>
                      <div className="mt-3 text-center">
                        <p className="footer-text-color">{i18next.t('Login PG Don\'t have an account?')} <Link to="/register" className="fw-medium text-underline">{i18next.t('Login PG Signup now')}</Link> </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="footer-text-color"> © {new Date().getFullYear()} Tokenizedgreen.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="rightImg">
            <div className="bgLogin"></div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);