import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal, Label, Input, Button } from "reactstrap";

// Formik Validation
import { Link, withRouter} from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import "../../assets/scss/custom/style.css";

import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Portfolio = () => {
  //meta title
  document.title = i18next.t('My_Portfolio PG document title');
  const { getRequest, postRequest } = useRequests();

  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0 });
  const showTooltip = (event) => {
    setTooltip({ visible: true, x: event.pageX + 15, y: event.pageY + 15 });
  };
  const hideTooltip = () => {
    setTooltip({ visible: false, x: 0, y: 0 });
  };
  

  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};

  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    validation1.setValues({
      ...validation1.values,
      ct_id: elementData.ct_id,
      currency: 'Euro'
    });
    setStandardModal1(true);
  }

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      ct_id: '',
      currency: 'Euro',
    },
    validationSchema: Yup.object({
      currency: Yup.string().required(i18next.t('Property_Detail PG Please select currency'))
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          ct_id: values.ct_id,
          currency: values.currency,
        });
        const res = await postRequest('claim-fund', jsonpar);
        if (res && res.code === 200) {
          handleLoadGetPropertyInvestment();

          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();
        
        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');

        setStandardModal1(false);
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const handleClickCryptoBalanceRefresh = async () => {
    const res = await getRequest('crypto-data-refresh');
    if (res && res.code === 200) {
      handleLoadGetPropertyInvestment();

      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [TotalInvestment, setTotalInvestment] = React.useState({
		total_value: 0,
    total_value_token_price: 0
	});
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => {
        let startDate = moment.unix(element.time_stamp);
        let endDate;

        switch (element.lock_in_period) {
            case 'year':
                endDate = startDate.clone().add(element.lock_in_period_no, 'years');
                break;
            case 'month':
                endDate = startDate.clone().add(element.lock_in_period_no, 'months');
                break;
            case 'day':
                endDate = startDate.clone().add(element.lock_in_period_no, 'days');
                break;
            default:
                endDate = startDate;
        }

        const isLockInPeriodOver = moment().isAfter(endDate);

        return (
          <tr key={index}>
            <td 
              onMouseEnter={(e) => showTooltip(e)}
              onMouseLeave={hideTooltip}
              onClick={() => window.open(`https://polygonscan.com/tx/${element.hash}`, '_blank')}
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              >
              {element.hash}
            </td>
            <td>{startDate.format('YYYY-MM-DD | HH:mm')}</td>
            <td>{element.value}</td>
            <td>{parseFloat(element.value) * parseFloat(element.token_price)} EUR </td>
            <td>{element.lock_in_period_no} {element.lock_in_period_no > 1? element.lock_in_period + 's' : element.lock_in_period}</td>
            <td align="center" style={{ width: '160px' }}>
              {element.is_claimed === 1 ? 
                <a href="#" className="btn btn-sm btn-light pad25">{i18next.t('Claimed')}</a> :
                isLockInPeriodOver ? <Button className="btn btn-primary exchange-right-btn me-2 btn btn-secondary" onClick={(e)=>togStandardModal1(element)}>{i18next.t('Claim Fund')}</Button> : '-'}
            </td>
          </tr>
        );
    });

    setTransactionTBody(tempTBody);
  }
  const handleLoadGetPropertyInvestment = async () => {
    const res = await getRequest('get-property-investment');
    if (res && res.code === 200) {
      handleT1Body(res.data.crypto_transaction);

      setTotalInvestment({
        ...TotalInvestment,
        total_value: res.data.total_value,
        total_value_token_price: res.data.total_value_token_price
      });
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="6" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }
  React.useEffect(() => {
    handleLoadGetPropertyInvestment();
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('PG breadcrumbItem My_Portfolio')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio" className="active"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="d-flex mobileFullWidth">
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5 className="fullWidth maticRefresh"><span>{i18next.t('My Investment')}</span>
                                <div className="font-size-24 text-primary send-receive" onClick={(e) => handleClickCryptoBalanceRefresh()}>
                                  <i className="mdi mdi-reload" />
                                </div>
                              </h5>
                              <p className="mb-1">{TotalInvestment.total_value} Tokens ({TotalInvestment.total_value_token_price} EUR)</p>
                            </div>
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="InvestmentBtn">
                              <Link to="/property-details" className="btn-sm btn btn-primary pad25">{i18next.t('Invest Now')}</Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{ minWidth: '1000px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('Transaction Hash')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th>{i18next.t('Tokens')}</th>
                                <th>{i18next.t('Current Token Value')}</th>
                                <th>{i18next.t('Lock-In Period')}</th>
                                <th align="center" style={{ width: '160px' }}>{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t('Claim Fund')}</h5>
          <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group cstm-select-country fullWidth mb-4">
            <div className="fullWidth">
              <select className="form-select" name="currency" value={validation1.values.currency} onChange={(e) => validation1.setValues({ ...validation1.values, currency: e.target.value })}>
                {/* <option value="MATIC">MATIC</option>
                <option value="USDT">USDT</option> */}
                <option value="Euro">Euro</option>
              </select>
            </div>
          </div>
          <Label className="form-label note-lable note mb-0 mt-1"><b>{i18next.t('Note')}:</b> {i18next.t('My_Portfolio PG label')}<br/><br/>{i18next.t('My_Portfolio PG description')}</Label>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary"
            id="submit1"
            onClick={(e) => {
              e.preventDefault();
              validation1.handleSubmit();
              return false;
            }}
          >{i18next.t('Submit')}</button>
          <button type="button" className="btn btn-light" data-dismiss="modal" onClick={(e)=>setStandardModal1(false)} >{i18next.t('Close')}</button>
        </div>
      </Modal>
      {tooltip.visible && (
        <div 
        style={{
          position: 'absolute', 
          top: tooltip.y, 
          left: tooltip.x, 
          padding: '5px 10px', 
          backgroundColor: 'white', 
          color: 'black',             
          borderRadius: '4px', 
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          fontSize: '0.875rem',       
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)', 
        }}
        >
          View Transaction
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(Portfolio);
